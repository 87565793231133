import { storageAvailable, storageGet, storageSet } from '../utils/localStorage';
var TVState;
(function (TVState) {
    TVState["ON"] = "tvON";
    TVState["OFF"] = "tvOFF";
})(TVState || (TVState = {}));
function TVModeEnabler() {
    const urlParams = new URLSearchParams(window.location.search);
    const tvON = TVState.ON;
    const tvOFF = TVState.OFF;
    if (storageAvailable()) {
        const isTVModeEnabled = Boolean(storageGet(tvON));
        if (urlParams.has(tvON) || isTVModeEnabled) {
            storageSet(tvON, 1);
            document.documentElement.classList.add(tvON);
        }
        if (urlParams.has(tvOFF)) {
            localStorage.removeItem(tvON);
            document.documentElement.classList.remove(tvON);
        }
    }
}
export default TVModeEnabler;
