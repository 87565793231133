function navBarHandler() {
  // 0 - no fetched, no loaded
  // 1 - fetched, no loded
  // 2 - fetched and loaded
  let submenuFetchStatus = 0;
  let submenuContent = '';
  let lastClicked;

  document.querySelector('html').addEventListener('click', (e) => {
    const el = e.target;
    const root = document.querySelector('html');
    const navBar = document.querySelector('.navbar');
    if (!navBar) {
      return;
    }
    const menuSearch = navBar.querySelector('.navbar__search');
    const mainMenu = navBar.querySelectorAll('.navbar__menu__ul > li');
    const mobileMenuToggler = navBar.querySelector('.navbar__toggle');
    const liveNowTop = document.querySelector('.liveNowTop');
    const liveNowTopDropdowns = document.querySelectorAll(
      '[data-nav=liveEventsChilds]',
    );

    function isMobileMode() {
      return window.getComputedStyle(mobileMenuToggler).display !== 'none';
    }

    function hideSearch() {
      navBar.classList.remove('navbar--searchOn');
    }

    function hideUserMenu() {
      navBar.classList.remove('navbar--userMenuOn');
    }

    function removeMenuAllActives() {
      [...mainMenu].forEach((item) => {
        item.classList.remove('on');
        if (isMobileMode()) {
          item.classList.remove('active');
        }
      });
      hideSearch();
      hideUserMenu();
    }

    function removeLiveNowAllActives() {
      [...liveNowTopDropdowns].forEach((item) => {
        item.parentNode.classList.remove('on');
      });
    }

    function hideLiveNow() {
      liveNowTop.classList.remove('open');
    }

    function openSearch() {
      navBar.classList.add('navbar--searchOn');
      document.getElementById('js-siteSearchInput').focus();
    }

    function openUserMenu() {
      if (!navBar.classList.contains('navbar--userMenuOn')) {
        navBar.classList.add('navbar--userMenuOn');
      } else {
        hideUserMenu();
      }
    }

    function hideLRDashboard() {
      const target = document.getElementById('moderateDashboard');
      if (target) {
        target.dispatchEvent(new Event('menu-close', null));
        target.style.display = 'none';
      }
    }

    function isNavElement(navElement, navName) {
      let currentElement = navElement;
      // Create a new variable to hold the current element

      while (currentElement && currentElement.nodeName !== 'HTML') {
        if (navName === currentElement.getAttribute('data-nav')) {
          return true;
        }
        currentElement = currentElement.parentNode;
      }
      return false;
    }

    // Close all navbar dropdowns on outside click
    if (!el.parentElement || !el.parentNode.closest('.navbar') || el.className === 'navbar') {
      // Submenu has room widget with T&C buttons.
      // Do not close submenu dropdown if we close T&C popup.
      if (el.classList.contains('modalWindowCloseButton')) {
        return;
      }

      removeMenuAllActives();
      root.classList.remove('activeDesktopMenu', 'activeMenu');
    }

    // Close all LiveNow dropdowns on outside click
    if (liveNowTop) {
      if (!el.parentElement || !el.parentElement.closest('.liveNowTop')) {
        removeLiveNowAllActives();
        hideLiveNow();
      }
    }

    function insertSubmenu() {
      const lastClickedEl = lastClicked;

      if (lastClickedEl.submenuFilled) {
        return;
      }
      lastClickedEl.submenuFilled = true;

      const data = submenuContent;
      const menuID = lastClickedEl.querySelector('[data-pageid]').dataset.pageid;
      const column1 = el.querySelector('.dropdownMenu__content1');
      const column2 = lastClickedEl.querySelector('.dropdownMenu__content2');

      // If json doesn't have info about the tab and the tab is already filled, do nothing
      if (!(data.menu && menuID in data.menu)) {
        return;
      }
      const { 1: column1Data, 2: column2Data } = data.menu[menuID];

      if (column1Data) {
        column1.innerHTML = column1Data;
      } else {
        column1.parentNode.classList.add('noFirstColumn');
      }

      if (column2Data) {
        column2.innerHTML = column2Data;
      } else {
        column2.parentNode.classList.add('noSecondColumn');
      }
    }

    function subMenuContentInit(subEl) {
      lastClicked = subEl;

      if (submenuFetchStatus === 2) {
        insertSubmenu();
      } else if (submenuFetchStatus === 0) {
        const preloader = el.querySelector('.preloader');
        preloader.style.display = 'block';

        submenuFetchStatus = 1;
        fetch('/navigation.json')
          .then((response) => response.json())
          .then((data) => {
            submenuContent = data;
            submenuFetchStatus = 2;
            insertSubmenu();
            preloader.style.display = 'none';
          })
          .catch((error) => {
            throw new Error(`An error occurred while fetching the navigatin endpoint: ${error.message}`);
          });
      }
    }

    switch (true) {
      case isNavElement(el, 'navbarSearch'):
        // Open on search button click
        if (!menuSearch.classList.contains('on')) {
          openSearch();
        }
        // Close search on close button click
        if (el.classList.contains('closeSearch')) {
          hideSearch();
        }
        break;

      case isNavElement(el, 'livestream'):
        e.stopPropagation();
        e.preventDefault();
        window.location.href = el.dataset.livestreamurl;
        break;

      case isNavElement(el, 'liveEventsChilds'):
        [...liveNowTopDropdowns].forEach((item) => {
          if (el === item) {
            item.parentNode.classList.toggle('on');
          } else {
            item.parentNode.classList.remove('on');
          }
        });
        break;

      case isNavElement(el, 'mobileMenuToggler'):
        if (liveNowTop) {
          hideLiveNow();
        }

        if (!root.classList.contains('activeMenu')) {
          root.classList.add('activeMenu');
        } else {
          root.classList.remove('activeMenu');
        }
        break;

      case isNavElement(el, 'liveEventsToggler'):
        liveNowTop.classList.toggle('open');
        mobileMenuToggler.checked = false;
        root.classList.remove('activeMenu');
        break;

      case isNavElement(el, 'avatar'):
        openUserMenu();
        break;

      case isNavElement(el, 'hideUserMenu'):
        hideUserMenu();
        break;

      case isNavElement(el, 'navbarMenu'):
        if (isMobileMode()) {
          // ******* PHONE *********

          // Close active dropdown if you click on it twice
          if (el.classList.contains('on')) {
            removeMenuAllActives();
            return;
          }

          // If clicked parent is main menu, add "activeSubmenuMenu"
          if (el.parentElement.classList.contains('navbar__menu__ul')) {
            removeMenuAllActives();
          }
        } else {
          if (el.classList.contains('navbar__menu__firstlevel')) {
            subMenuContentInit(el);
            root.classList.add('activeDesktopMenu');
          }
          // ******* DESKTOP *********
          // Do not close dropdown if you click on submenu element. Bad expierence
          if (
            el.parentNode.closest('li.dropdown.on') || el.parentNode.closest('div.dropdown.on')
          ) {
            return;
          }
          // Close active dropdown if you click on it twice
          if (el.classList.contains('on')) {
            removeMenuAllActives();
            root.classList.remove('activeDesktopMenu');
            return;
          }
          removeMenuAllActives();
        }

        el.classList.add('on');

        hideLRDashboard();
        break;
      default:
        break;
    }
  });
}

export default navBarHandler;
